import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC2PCZHbtzRLBnZnlNwPR9mbhsgGFS1toM",
  authDomain: "thepenguinstudio-zequlard.firebaseapp.com",
  projectId: "thepenguinstudio-zequlard",
  storageBucket: "thepenguinstudio-zequlard.appspot.com",
  messagingSenderId: "882032908345",
  appId: "1:882032908345:web:006d74f56baadd63e515e8",
  measurementId: "G-PLM715PCDT"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

export { auth, db, storage };