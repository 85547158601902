import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css'; // 確保導入 CSS
import { auth } from '../firebase';

const Login = () => {
  const [statusMessage, setStatusMessage] = useState('Initializing FirebaseUI...');

  useEffect(() => {
    console.log("Initializing FirebaseUI...");

    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      console.log("Creating new AuthUI instance");
      ui = new firebaseui.auth.AuthUI(auth);
    } else {
      console.log("Using existing AuthUI instance");
    }

    const uiConfig = {
      signInSuccessUrl: '/',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      tosUrl: '',
      privacyPolicyUrl: '',
    };

    ui.start('#firebaseui-auth-container', uiConfig);
    setStatusMessage('FirebaseUI initialized successfully');
    console.log("FirebaseUI initialized successfully");
  }, []);

  return (
    <div>
      <h1>Login</h1>
      <p>{statusMessage}</p>
      <div id="firebaseui-auth-container"></div>
    </div>
  );
};

export default Login;