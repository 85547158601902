import axios from 'axios';

const addDiaryEntry = async (text) => {
  try {
    const response = await axios.post('https://us-central1-thepenguinstudio-zequlard.cloudfunctions.net/addDiaryEntry', { text });
    return response.data;
  } catch (error) {
    console.error('Error adding diary entry:', error);
    throw error;
  }
};

const getOpenAIData = async (prompt) => {
  try {
    const response = await axios.post('https://us-central1-thepenguinstudio-zequlard.cloudfunctions.net/getOpenAIData', { prompt });
    return response.data;
  } catch (error) {
    console.error('Error fetching OpenAI data:', error);
    throw error;
  }
};

const fetchDiaryEntries = async () => {
  try {
    const response = await axios.get('https://us-central1-thepenguinstudio-zequlard.cloudfunctions.net/fetchDiaryEntries');
    return response.data;
  } catch (error) {
    console.error('Error fetching diary entries:', error);
    throw error;
  }
};

export { addDiaryEntry, getOpenAIData, fetchDiaryEntries };