import React, { useState } from 'react';
import { getOpenAIData } from '../api';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const sendMessage = async () => {
    if (input.trim()) {
      const userMessage = { role: 'user', content: input };
      setMessages([...messages, userMessage]);

      try {
        const response = await getOpenAIData(input);
        const botMessage = { role: 'bot', content: response.choices[0].text };
        setMessages([...messages, userMessage, botMessage]);
      } catch (error) {
        console.error('Error fetching AI response:', error);
      }

      setInput('');
    }
  };

  return (
    <div>
      <h1>Chat with ChatGPT</h1>
      <div>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            {message.content}
          </div>
        ))}
      </div>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a message..."
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default Chat;